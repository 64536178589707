import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import "./Landing.css"; // Ensure you have the relevant CSS for styling
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const logoControls = useAnimation();
  const containerControls = useAnimation();

  useEffect(() => {
    const animateSequence = async () => {
      await logoControls.start({
        opacity: 1,
        transition: { duration: 0.7, ease: "easeInOut" },
      });

      setTimeout(async () => {
        await Promise.all([
          logoControls.start({
            // scale: 0.5,
            // y: 40,
            y: 0,
            opacity: 1,
            transition: { duration: 0.7, ease: "easeInOut" },
          }),
          containerControls.start({
            y: 0,
            opacity: 1,
            transition: { duration: 0.9, ease: "easeInOut" },
          }),
        ]);
        setIsLoaded(true);
      }, 500);
    };

    animateSequence();
  }, [logoControls, containerControls]);

  return (
    <div className="landing-container">
      <motion.div
        className="logo-container"
        initial={{ opacity: 0, y: 100 }}
        animate={logoControls}
      >
        <img src="/tooig-logo-dark.png" alt="Tooig Logo" className="logo" />
      </motion.div>

      <motion.div
        className="content"
        initial={{ y: 100, opacity: 0 }}
        animate={containerControls}
      >
        <h1>A Higher Definition of Learning</h1>
        <p>
          <span className="Emphasis">
            Something is wrong with how we learn.
          </span>
          <br /> And we're trying to reimagine it.
          <br />
          <br />
          Now, we think we've made a truly giant leap.{" "}
          <span className="Emphasis">
            We think we've created our most powerful learning tool yet.
          </span>{" "}
          It's genuinely magical. And we'd love to share it with you. We invite
          you to join us in shaping the future of how we learn. We
          believe with your help we can push the boundaries further and create
          something even more extraordinary.
          <br />
          <br />
          <span className="faded">Waitlist coming soon...</span>
        </p>
        {/* <Link to="/form" className="waitlist-button">
          Join the waitlist
        </Link> */}
      </motion.div>
    </div>
  );
};

export default LandingPage;
