import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./FormSlider.css";

const FormSlider = () => {
  const [step, setStep] = useState(0);
  const [direction, setDirection] = useState(1);
  const [formData, setFormData] = useState({
    question1: "",
    question2: "",
    question3: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const response = await fetch("/api/form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert("Form submitted successfully!");
    } else {
      alert("Failed to submit the form");
    }
  };

  const steps = [
    {
      id: 0,
      content: (
        <div>
          <h2>Thanks for your interest. We're excited you to have you.</h2>
          <p>
            We'll be asking you a series of questions to help us uderstand you.
            Your responses will be used to determine your place on the queue of
            people we'll be granting access to out Alpha software.
          </p>
          <button onClick={() => changeStep(1)}>Next</button>
        </div>
      ),
    },
    {
      id: 1,
      content: (
        <div>
          <h2>What improvements would you like to see in education?</h2>
          <textarea
            name="question2"
            value={formData.question2}
            onChange={handleChange}
            placeholder="Type your response here..."
          />
          <button onClick={() => changeStep(-1)}>Back</button>
          <button onClick={() => changeStep(1)}>Next</button>
        </div>
      ),
    },
    {
      id: 2,
      content: (
        <div>
          <h2>Any additional comments or suggestions?</h2>
          <textarea
            name="question3"
            value={formData.question3}
            onChange={handleChange}
            placeholder="Type your response here..."
          />
          <button onClick={() => changeStep(-1)}>Back</button>
          <button onClick={handleSubmit}>Submit</button>
        </div>
      ),
    },
  ];

  const changeStep = (direction) => {
    setDirection(direction);
    setStep(step + direction);
  };

  const variants = {
    enter: (direction) => ({
      y: direction > 0 ? 50 : -50,
      opacity: 0,
    }),
    center: {
      y: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      y: direction > 0 ? -50 : 50,
      opacity: 0,
    }),
  };

  return (
    <div className="form-slider-container">
      <img src="/tooig-logo-dark.png" alt="Tooig Logo" className="logo-1" />
      <AnimatePresence custom={direction} mode="wait">
        {/* <motion.div
            key={step}
            // custom={direction}
            // variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.4 }}
            className="logo-1"
          >
            <img src="/tooig-logo-dark.png" alt="Tooig Logo" className="logo-1" />
          </motion.div> */}

        <motion.div
          key={step}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.4 }}
          className="form-step"
        >
          {steps[step].content}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default FormSlider;
