import { Analytics } from '@vercel/analytics/react';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './landing-old';
import '../index.css';
import FormSlider from './FormSlider';
import Insider from './insider/Insider';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/form" element={<FormSlider />} />
          <Route path="/insider" element={<Insider />} />
        </Routes>
        <Analytics />
      </div>
    </Router>
  );
}

export default App;
